import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Checkbox = ({ checked, ...props }) => (
  <S.CheckboxContainer>
    <S.HiddenCheckbox checked={checked} {...props} />
    <S.StyledCheckbox checked={checked} {...props}>
      <S.Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </S.Icon>
    </S.StyledCheckbox>
  </S.CheckboxContainer>
)

Checkbox.propTypes = {
  checked: PropTypes.bool,
}

export default Checkbox
