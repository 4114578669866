import React from 'react'
import { useIsExamsContext } from '../../../utils/isExamsContext'

import Checkbox from '../../shared/Checkbox'

import * as S from './styled'

const FiltersExams = (data) => {
  const { 
    specialtySelected,
    ansFilter,
    handleChangeCheckboxFilter
  } = useIsExamsContext()

  return (
      <S.SidebarListItemHolder>
        {data.items.length > 0 &&
          data.items.map(item => (
            <S.SidebarListItem key={`${data.attribute}${item.id}`}>
              <S.CheckboxHolder>
                <S.CheckboxHolderDivider>
                  <Checkbox 
                    checked={
                      data.attribute === 'specialty' 
                      ?
                        specialtySelected.includes(item.id)
                      :
                        ansFilter === item.id
                    }
                    onChange={() => (
                      handleChangeCheckboxFilter(item.id, data.attribute)
                    )} 
                    examsList
                  />
                  <S.SpecialtyText>
                    {item.nome}
                  </S.SpecialtyText>
                </S.CheckboxHolderDivider>
              </S.CheckboxHolder>
            </S.SidebarListItem>
          ))
        }
      </S.SidebarListItemHolder>
    )
}

export default FiltersExams
