import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useIsExamsContext } from '../../utils/isExamsContext'

import * as S from './styled'

const ClearRefinements = () => {
  const { 
    specialtySelected,
    setSpecialtySelected
  } = useIsExamsContext()

  return (
    <S.ClearRefinement onClick={() => setSpecialtySelected([])} disabled={!specialtySelected.length}>
      Limpar filtros
      <S.RefinementsItemIcon>
        <FontAwesomeIcon icon={faTimes} />
      </S.RefinementsItemIcon>
    </S.ClearRefinement>
  )
}

export default ClearRefinements
