import React from 'react'
import FiltersExams from './FiltersExams'
import AccordionDasa from '../../shared/AccordionDasa'
import { useIsExamsContext } from '../../../utils/isExamsContext'
import useIsBrazil from '../../../utils/isBrazil'
import * as S from './styled'

const Sidebar = ({ data }) => {
  const isBrazil = useIsBrazil()
  const { specialtyData, ansFilterOptions } = useIsExamsContext()

  const pageData = data.wordpressWpGatsbyPage.acf

  const filters = [
    {
      title: pageData.title_areas_filter,
      content: <FiltersExams items={specialtyData} attribute="specialty" />,
    }
  ]

  if(isBrazil) {
    filters.push({
      title: pageData.title_ans_filter,
      content: <FiltersExams items={ansFilterOptions} attribute="ans" />,
    })
  }

  return (
    <S.SidebarFilters>
      <AccordionDasa items={filters} accordionExams />
    </S.SidebarFilters>
  )
}

export default Sidebar
