import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? '#000E40' : 'transparent')};
  transition: all 150ms;
  border: 2px solid ${props => props.examsList ? '#000E40' : alvTokens.ColorPaletteWhite};
  border-radius: 5px;

  ${HiddenCheckbox}:focus + & {
    /* box-shadow: 0 0 0 3px '#D22673'; */
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }

  ${media.lessThan('medium')`
    border: 2px solid ${alvTokens.ColorPaletteWhite};
  `}
`

export const StyledCheckboxDasa = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? '#000E40' : 'transparent')};
  transition: all 150ms;
  border: 2px solid #000E40;
  border-radius: 5px;

  ${HiddenCheckbox}:focus + & {
    /* box-shadow: 0 0 0 3px '#D22673'; */
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
