import styled from 'styled-components'
import media from 'styled-media-query'
import * as A from 'react-accessible-accordion'
import { alvTokens } from '@dasa-health/alma-tokens'

export const AccordionItemButton = styled(A.AccordionItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  position: relative;
`

export const AccordionItemButtonTitle = styled.h2`
  font-size: 12px;
  color: ${props => props.examsList ? props.theme.colors.brand.primary[10] : alvTokens.ColorPaletteWhite};
  text-transform: ${props => (props.singleExam ? 'none' : 'uppercase')};
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  margin: 0;

  ${media.lessThan('medium')`
    color: ${alvTokens.ColorPaletteWhite};
  `}
`

export const AccordionItem = styled(A.AccordionItem)`
  display: ${props => (props.hidden ? 'none' : 'block')};
  padding: 2.4rem;
  height: max-content;
  background: ${alvTokens.ColorPaletteWhite};
  border-radius: 4px;

  ${media.lessThan('medium')`
    background: none;
    padding: 0;
  `}
`

export const AccordionItemPanel = styled(A.AccordionItemPanel)`
  display: block;
  margin-top: 0.8rem;

  ${media.lessThan('medium')`
    margin: 0;
  `}
`

export const Accordion = styled(A.Accordion)`
  & > * {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    ${media.lessThan('medium')`
      border-bottom: none ;
    `}
  }
`
