import React from 'react'
import PropTypes from 'prop-types'

import * as Acc from 'react-accessible-accordion'
import * as S from './styled'

const AccordionDasa = props => {
  return (
    <S.Accordion data-cy="accordion">
      {props.items.map(item => (
        <S.AccordionItem key={item.title} uuid={item.uuid}>
          <Acc.AccordionItemHeading className='accordion-item-heading'>
            <S.AccordionItemButton>
              <S.AccordionItemButtonTitle examsList>
                {item.title}
              </S.AccordionItemButtonTitle>
            </S.AccordionItemButton>
          </Acc.AccordionItemHeading>
          <S.AccordionItemPanel>
            {item.content}
          </S.AccordionItemPanel>
        </S.AccordionItem>
      ))}
    </S.Accordion>
  )
}

AccordionDasa.propTypes = {
  items: PropTypes.array.isRequired,
}

export default AccordionDasa
