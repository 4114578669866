import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { ContainerDasa } from '../shared/container'

export const SearchContainer = styled(ContainerDasa)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: unset;
  top: 50vh;
  width: 100%;
  margin:  0 auto 3.8rem;

  ${media.lessThan('medium')`
    margin:  0 auto 3.2rem;
  `}
`

export const SearchDiv = styled.div`
  position: relative;
  width: 65%;
  margin: 0;

  ${media.lessThan('medium')`
    width: 90%;
    width: 100%;
  `}
`

export const SearchInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0 0 1.6rem 4.7rem;
  font-size: 24px;
  font-weight: 700;
  line-height: 133%;
  outline: 0;
  color: ${props => props.theme.colors.brand.primary[10]};

  &::placeholder {
    color: ${props => props.theme.colors.brand.primary[10]};
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  -webkit-appearance: textfield;

  ${media.lessThan('medium')`
    padding: 0 0 1rem 3rem;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
    font-size: 16px;

    &::placeholder {
      font-size: 14px;
    }
  `}
`

export const SearchButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  left: 0;
  cursor: pointer;

  ${media.lessThan('medium')`
    left: 0;
  `}
`

export const SearchIcon = styled.i`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: 3.1rem;

  ${media.lessThan('medium')`
    font-size: 2rem;
  `}
`

export const Divider = styled.div`
  width: 100%;
  background-color: var(--pink);
  height: .8rem;
  
  ${media.lessThan('medium')`
    height: .3rem;
  `}
`

export const LiveSearchList = styled.ul`
  width: 93%;
  background-color: var(--white);
  list-style: none;
  border-radius: 5px;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.2);
  padding: 3.5rem;
  margin: 0;

  ${media.between('large', 'huge')`
    padding: 2.5rem;
  `}
`

export const LiveSearchItem = styled.li`
  line-height: 3rem;

  ${media.between('large', 'huge')`
    line-height: 2rem;
  `}
`

export const LiveSearchTitle = styled(Link)`
  color: var(--pink);
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;

  &:hover {
    text-decoration: underline;
  }

  ${media.between('large', 'huge')`
    font-size: 16px;
  `}
`

export const LiveSearchSynonymies = styled.small`
  font-size: 16px;
  color: #7D7D7D;
  font-style: italic;
  line-height: 2rem;
  display: block;
  margin-bottom: 2.5rem;
  font-weight: 100;

  ${media.between('large', 'huge')`
    font-size: 14px;
  `}
`

export const LiveSearchSeeMore = styled(Link)`
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--pink);

  ${media.between('large', 'huge')`
    font-size: 14px;
  `}
`
