import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const SidebarFilters = styled.div`
  & > * {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    ${media.lessThan('medium')`
      border-bottom: none ;
    `}
  }
`

export const SidebarListItemHolder = styled.ul`
  list-style: none;
  margin: 1.6rem 0 0;
  padding: 0;

  & > * {
    margin-bottom: 1.6rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.lessThan('medium')`
    margin: 2.4rem 0 0;
  `}
`

export const SidebarListItem = styled.li`
  font-size: 14px;
  color: #7D7D7D;
`

export const CheckboxHolder = styled.label`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const CheckboxHolderDivider = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 400;
  line-height: 150%;
`

export const SpecialtyText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 400;
  line-height: 150%;
  margin-left: 1.8rem;
  letter-spacing: 0;

  ${media.lessThan('medium')`
    color: ${alvTokens.ColorPaletteWhite};
  `}
`
