import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIsExamsContext } from '../../utils/isExamsContext'
import { Search as SearchIcon } from '@dasa-health/alma-icons'

import * as S from './styled'
import { SearchContainer } from './styled'

const Search = ({ data }) => {
  const [firstRender, setFirstRender] = useState(true)
  const { 
    searchText, 
    setSearchText, 
    setPaginationData, 
    getExamsList,
    specialtySelected,
    ansFilter
  } = useIsExamsContext()

  const ph = data.wordpressWpGatsbyPage.acf.title_placeholder_exam_search

  useEffect(() => {
    if (searchText !== 'initial_param' && (searchText.length > 2 || searchText.length === 0)) {
      setPaginationData(pagData => ({...pagData, pageNumber: 1}))
      getExamsList(1)
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
      if(firstRender) return

      setPaginationData(pagData => ({...pagData, pageNumber: 1}))
      getExamsList(1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtySelected])

  useEffect(() => {
    if(firstRender) return setFirstRender(false)
    setPaginationData(pagData => ({...pagData, pageNumber: 1}))
    getExamsList(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [ansFilter])

  return (
      <SearchContainer>
        <S.SearchDiv>
          <S.SearchInput
            data-cy="search"
            type="search"
            placeholder={ph}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            required=""
            maxLength="512"
            onChange={event => setSearchText(event.currentTarget.value)}
            value={searchText !== 'initial_param' ? searchText : ''}
          />

          <S.SearchButton data-cy="search-button" type="button">
            <S.SearchIcon>
              <SearchIcon />
            </S.SearchIcon>
          </S.SearchButton>
        </S.SearchDiv>

        <S.Divider />
      </SearchContainer>
  )
}

Search.propTypes = {
  isExams: PropTypes.bool,
}

export default Search
