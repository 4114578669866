import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { useIsExamsContext } from '../../utils/isExamsContext'

const Pagination = ({ nbPages }) => {
  const { paginationData, setPaginationData, getExamsList } = useIsExamsContext()

  const handleClick = (event, type) => {
    event.preventDefault()

    const filter = validatePagination(type)

    if (filter === undefined || filter === null) return false;

    setPaginationData(paginationData => ({...paginationData, pageNumber: filter}))
    getExamsList(filter)

    const element = document.getElementById('exams-list');

    if(element) {
      element.scrollIntoView({behavior:"smooth", block: "start", inline:"nearest"});
    }
    
    return true
  }

  const validatePagination = type => {
    if(type === 'paginationNext') return paginationData.pageNumber + 1
    if(type === 'paginationPrev') return paginationData.pageNumber - 1
    if(type === 'paginationLast') return nbPages
  }

  const isAbleToPrev = () => paginationData.pageNumber - 1 < 0
  const isAbleToNext = () => paginationData.pageNumber + 1 > nbPages

  return (
    <S.PaginationList>
      <S.PaginationListItem>
        <S.PaginationListItemLink
          onClick={(event) => handleClick(event, 'paginationPrev')}
          disabled={isAbleToPrev()}
        >
          <S.PaginationIconLeft />
        </S.PaginationListItemLink>
      </S.PaginationListItem>
  
      <S.PaginationListItem>
        {paginationData.pageNumber > 1 && (
          <S.PaginationItem
            onClick={(event) => handleClick(event, 'paginationPrev')}
          >
            {paginationData.pageNumber - 1}
          </S.PaginationItem>
        )}
  
        <S.PaginationItemActive>{paginationData.pageNumber}</S.PaginationItemActive>
  
        {paginationData.pageNumber + 1 < nbPages && (
          <S.PaginationItem
            onClick={(event) => handleClick(event, 'paginationNext')}
          >
            {paginationData.pageNumber + 1}
          </S.PaginationItem>
        )}
  
        {paginationData.pageNumber + 1 < nbPages && (
          <S.PaginationItem disabled>
            ...
          </S.PaginationItem>
        )}
  
        {paginationData.pageNumber + 1 <= nbPages && (
          <S.PaginationItem
            onClick={(event) => handleClick(event, 'paginationLast')}
          >
            {nbPages}
          </S.PaginationItem>
        )}
      </S.PaginationListItem>
  
      <S.PaginationListItem>
        <S.PaginationListItemLink
          onClick={(event) => handleClick(event, 'paginationNext')}
          disabled={isAbleToNext()}
        >
          <S.PaginationIconRight />
        </S.PaginationListItemLink>
      </S.PaginationListItem>
    </S.PaginationList>
  )
}

Pagination.propTypes = {
  nbPages: PropTypes.number.isRequired,
}

export default Pagination
