import React from 'react'
import { Subtitle, Title } from '../shared/text-styled'

const Intro = ({ data }) => {
  const {
    wordpressWpGatsbyPage: {
      title,
      acf: { subtitle },
    },
  } = data

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </>
  )
}

export default Intro
