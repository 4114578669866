import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/layout'
import SEO from 'components/seo'
import { IsExamsProvider } from '../../utils/isExamsContext'

import Exams from 'components/exams'

const ExamesPage = ({ data, location, pageContext }) => {
  const wordpressPage = data.wordpressWpGatsbyPage.acf

  return (
    <IsExamsProvider>
      <Layout location={location} pageContext={pageContext}>
        <SEO
          title={wordpressPage.title}
          description={wordpressPage.subtitle}
          canonical={wordpressPage.canonical}
          pathName={location.pathname}
        />
        <Exams 
          data={data}
          slug={pageContext.slug}
        />
      </Layout>
    </IsExamsProvider>
  )
}

ExamesPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default ExamesPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      title
      acf {
        subtitle
        title
        canonical
        title_btn_apply_filter
        title_areas_filter_mobile
        title_placeholder_exam_search
        title_areas_filter
        title_ans_filter
        ans_label_exam_details
        others_names_exam_details
        title_diseases_section
        title_deadline_section
        description_deadline
        text_no_results
      }
    }
  }
`
